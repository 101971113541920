<template>
  <div class="container-fluid pt-5">
    <div class="row justify-content-center pb-2">
      <div
        class="
          col-xs-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 col-xxl-6
          text-center
        "
      >
        <svg
          width="56"
          height="73"
          viewBox="0 0 56 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.7224 41.9929C24.7224 40.28 25.1012 38.8635 25.8588 37.7435C26.6494 36.6235 27.7859 35.3882 29.2682 34.0376C30.3553 33.0494 31.1459 32.2259 31.64 31.5671C32.1671 30.8753 32.4306 30.1012 32.4306 29.2447C32.4306 28.0259 31.9365 27.0706 30.9482 26.3788C29.9929 25.6541 28.7082 25.2918 27.0941 25.2918C25.5459 25.2918 24.1624 25.6212 22.9435 26.28C21.7576 26.9059 20.7529 27.7953 19.9294 28.9482L13.9506 25.44C15.3341 23.3318 17.1953 21.7012 19.5341 20.5482C21.9059 19.3953 24.7059 18.8188 27.9341 18.8188C31.7224 18.8188 34.7529 19.6259 37.0259 21.24C39.3318 22.8541 40.4847 25.0941 40.4847 27.96C40.4847 29.3106 40.2541 30.4965 39.7929 31.5176C39.3647 32.5388 38.8212 33.4118 38.1624 34.1365C37.5365 34.8282 36.7129 35.6353 35.6918 36.5576C34.4729 37.6447 33.5835 38.5671 33.0235 39.3247C32.4635 40.0494 32.1835 40.9388 32.1835 41.9929H24.7224ZM28.4776 54.3953C27.1271 54.3953 25.9906 53.9671 25.0682 53.1106C24.1788 52.2212 23.7341 51.1506 23.7341 49.8988C23.7341 48.6471 24.1788 47.6094 25.0682 46.7859C25.9576 45.9294 27.0941 45.5012 28.4776 45.5012C29.8612 45.5012 30.9976 45.9294 31.8871 46.7859C32.7765 47.6094 33.2212 48.6471 33.2212 49.8988C33.2212 51.1506 32.76 52.2212 31.8376 53.1106C30.9482 53.9671 29.8282 54.3953 28.4776 54.3953Z"
            fill="#333333"
          />
          <path
            d="M56 37C56 52.464 43.464 65 28 65C12.536 65 0 52.464 0 37C0 21.536 12.536 9 28 9C43.464 9 56 21.536 56 37ZM4.62476 37C4.62476 49.9098 15.0902 60.3752 28 60.3752C40.9098 60.3752 51.3752 49.9098 51.3752 37C51.3752 24.0902 40.9098 13.6248 28 13.6248C15.0902 13.6248 4.62476 24.0902 4.62476 37Z"
            fill="#333333"
          />
        </svg>
        <h2 class="title-h1 text-center p-3">
          <u>Preguntas frecuentes</u>
        </h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xs-11 col-sm-11 col-md-9 col-lg-8 col-xl-5 col-xxl-6">
        <div class="accordion" id="accordionQuestions">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  @click="arrowToggle(0)"
                  v-if="arrows[0]"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Cuáles son las condiciones de servicio?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowDown.svg"
                      alt="ibancar"
                      data-toggle="collapseOne"
                    />
                  </div>
                </button>
                <button
                  class="btn btn-link btn-block text-left"
                  type="button"
                  @click="arrowToggle(0)"
                  v-if="!arrows[0]"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Cuáles son las condiciones de servicio?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowUp.svg"
                      alt="ibancar"
                      data-toggle="collapseOne"
                    />
                  </div>
                </button>
              </h2>
            </div>

            <div
              ref="collapseOne"
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionQuestions"
            >
              <div class="card-body pl-2">
                <ul>
                  <li>
                    <span class="green-text pr-2">✓</span> Vehículo con máximo
                    15 años de antigüedad y libre de adeudos.
                  </li>
                  <li>
                    <span class="green-text pr-2">✓</span> Vehículo a nombre del
                    solicitante.
                  </li>
                  <li>
                    <span class="green-text pr-2">✓</span> Ingresos mínimos de
                    $9,000 MXN.
                  </li>
                  <li>
                    <span class="green-text pr-2">✓</span> Vehículo ubicado en
                    CDMX, Estado de México, Morelos, Querétaro ó Puebla.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(1)"
                  v-if="arrows[1]"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Cuáles son mis beneficios como cliente?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowDown.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(1)"
                  v-if="!arrows[1]"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Cuáles son mis beneficios como cliente?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowUp.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              ref="collapseTwo"
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionQuestions"
            >
              <div class="card-body pl-5 pr-3">
                <p class="card-text">
                  En Ibancar nos esforzamos por ofrecer el mejor servicio financiero a nuestros clientes en México. Nuestro proceso de solicitud es en línea, lo que significa que no requerimos copias de Ilaves, visitas al taller ni trámites notariales complicados. En tan sólo 48 horas, puedes obtener el préstamo que necesitas. Y lo más importante, el coche sigue siendo tuyo y puedes seguir manejandolo.
                </p>
                <p class="card-text">
                  Además, asignamos un ejecutivo personal para brindarte apoyo individualizado durante todo el proceso de solicitud. En Ibancar, flexibilizamos las condiciones y facilitamos el acceso a préstamos a personas con Buró de Crédito negativo, entre otras ventajas que nos distinguen de otras empresas.
                </p>
                <p class="card-text">
                  Además tu préstamo en Ibancar incluye un paquete de servicios útiles e interesantes que protegen tu auto y patrimonio.
                </p>
                <div class="text-center p-3">
                  <button
                    class="btn-orange"
                    @click="goTo('/como-funciona', '#ventajas')"
                  >
                    Ver todas las ventajas
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(2)"
                  v-if="arrows[2]"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Qué documentación tengo que enviar?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowDown.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(2)"
                  v-if="!arrows[2]"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Qué documentación tengo que enviar?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowUp.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionQuestions"
            >
              <div class="card-body pl-5 pr-5">
                <p class="card-text">
                  Estamos a tu disposición por el canal que prefieras. Puedes enviarnos la documentación por correo o WhatsApp, y siempre tendrás nuestro teléfono para consultar dudas.
                </p>
                <p class="card-text">
                  La documentación que necesitamos es la siguiente:
                </p>
                <h4 class="title-h4">Documentación Personal</h4>
                <ul>
                  <li>
                    <a
                      href="#"
                      @click="
                        openModal(
                          'modal_dni',
                          'Parte delantera y trasera de documento de identidad. Sin sombras ni reflejos del flash, sin cortar esquinas y con un fondo blanco ',
                          ['ine2.png','ine1.png']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >INE escaneada por ambos lados</a
                    >
                  </li>
                  <li class="d-none">
                    <a
                      href=""
                      @click="
                        openModal('imagen_selfie', ' Foto Selfie con DNI ',  ['inelanding.png'])
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                    >
                      Selfie con INE
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      @click="
                        openModal(
                          'modal_justificante_ingresos',
                          'Nómina del último mes',
                          ['nomina.png']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Comprobante de ingresos (último recibo de nómina o 2 estados de cuenta)
                    </a>
                  </li>
                  <li class="d-none">
                    <a
                      href=""
                      @click="
                        openModal(
                          'modal_justificante_ingresos',
                          'Carátula bancaria',
                          ['caratula.png']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Carátula bancaria de tu cuenta</a
                    >
                  </li>
                  <li>
                    <a
                      href=""
                      @click="
                        openModal(
                          'modal_justificante_ingresos',
                          'Documentos validos: Recibos de Luz, Agua, Gas, Internet, Telefono, Impuesto predial, Estado de cuenta Bancario', ['comprobantes.png']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Comprobante de domicilio  (máximo 3 meses de antigüedad)</a
                    >
                  </li>
                  <li class="d-none">
                    <a
                      href=""
                      @click="
                        openModal(
                          'modal_justificante_ingresos',
                          'Constancia de situación fiscal', ['contanciafiscal.webp']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Constancia de situación fiscal</a
                    >
                  </li>
              
                  
                </ul>
                <h4 class="title-h4">Documentación del coche</h4>
                <ul>
                  <li>
                    <a
                      href="#"
                      @click="
                        openModal(
                          'permiso_circulacion',
                          'Factura original',
                          ['factura.webp']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Factura original del coche escaneada</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      @click="
                        openModal(
                          'permiso_circulacion',
                          'Tarjeta de circulación', ['circulacion.jpg']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Tarjeta de circulación</a
                    >
                  </li>
                  
                  <li>
                    <a
                      href="#"
                      @click="
                        openModal(
                          'cuentakilometros',
                          'Fotos del coche (4 fotos desde los cuatro lados del coche)', ['4fotos.webp']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >Fotografías del exterior del coche: frontales, laterales y traseras</a>
                      /

                    <a
                      href="#"
                      @click="
                        openModal(
                          'video',
                          'Video del coche (1 video alrededor del coche)',
                          ['video.webp']
                        )
                      "
                      data-toggle="modal"
                      :data-target="'#' + nombre_modal"
                      >1 Video del coche</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(3)"
                  v-if="arrows[3]"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Puedo liquidar el préstamo en cualquier momento?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowDown.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(3)"
                  v-if="!arrows[3]"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Puedo liquidar el préstamo en cualquier momento?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowUp.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionQuestions"
            >
              <div class="card-body pl-5 pr-5 pb-3">
                <p class="card-text">
                  En Ibancar entendemos que en ciertas situaciones las personas pueden necesitar dinero de manera urgente, pero confían en que podrán resolver su problema de liquidez en los próximos meses. Por eso, ofrecemos la opción de liquidar tu préstamo en el momento que desees, sin ninguna penalización.
                </p>
                <p class="card-text">
                  En Ibancar, nos preocupamos por brindarte opciones flexibles para que puedas adaptar el préstamo a tus necesidades y tener la libertad de liquidarlo cuando mejor te convenga.
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(4)"
                  v-if="arrows[4]"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Cómo se paga el capital de mi préstamo?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowDown.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
                <button
                  class="btn btn-link btn-block text-left collapsed"
                  type="button"
                  @click="arrowToggle(4)"
                  v-if="!arrows[4]"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <div
                    class="
                      d-flex
                      flex-row
                      justify-content-between
                      align-items-center
                    "
                  >
                    <h4 class="title-h4 pr-3">
                      ¿Cómo se paga el capital de mi préstamo?
                    </h4>
                    <img
                      width="19"
                      height="12"
                      class="icon-fluid"
                      src="./../../assets/img/arrowUp.svg"
                      alt="ibancar"
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordionQuestions"
            >
              <div class="card-body pl-5 pr-5 pb-3">
                <p class="card-text">
                  En Ibancar comenzarás a pagar el capital e intereses desde el primer pago mensual, y no hay un pago final. Esto significa que siempre pagarás la misma cantidad cada mes.
                </p>
                <p class="card-text">
                  Además, tú eliges la fecha de pago que más te convenga. Te pediremos que nos indiques la fecha que mejor se adapte a tus necesidades, para que puedas realizar el pago en el momento más conveniente para ti.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center p-4">
      <div class="col-12 text-center p-5">
        <button
          type="button"
          class="btn-orange text-center p-2"
          @click="goTo('/', '')"
        >
          Solicitar préstamo
        </button>
      </div>
    </div>
    <div>
      <ModalGenerica :modalName="nombre_modal" :title="title" :img="img_url" />
    </div>
  </div>
</template>

<script>
import ModalGenerica from "@/components/ComoFunciona/ModalGenerica.vue";

export default {
  name: "CreditoCocheS4",
  components: {
    ModalGenerica,
  },
  data() {
    return {
      arrows: new Array(5).fill(true),
      name: "",
      nombre_modal: "",
      title: "",
      img_url: null,
    };
  },
  methods: {
    arrowToggle(collapseArea) {
      let position = collapseArea;
      this.arrows[position] = !this.arrows[position];
    },
    goTo(route, hash) {
      this.$router.push({ path: route, hash: hash });
    },
    openModal(nombre, title, img_url) {
      this.nombre_modal = nombre;
      this.title = title;
      this.img_url = img_url;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-link {
  text-decoration: none;
  color: #333;
}
.btn:focus,
.btn.focus {
  outline: none;
  box-shadow: none;
}
#accordionQuestions {
  color: #777;
  .card {
    border: none;
    border-bottom: 1px solid #dadada;
  }
  .card-header {
    border: none;
    background-color: #fff;
  }
  .card-body {
    padding: 0;
  }
}
#collapseOne {
  ul {
    list-style-type: none;
    color: #777;
  }
}

.green-text {
  font-weight: bold;
  color: #4ac068;
}

ul li a {
  text-decoration: none;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
  #collapseOne {
    ul {
      list-style-type: none;
      color: #777;
    }
    li {
      font-size: 14px;
      padding: 0px 0px 10px 0px;
    }
  }
  #collapseThree {
    li {
      font-size: 18px;
      padding: 10px;
    }
  }
  #accordionQuestions {
    .card-text {
      font-size: 14px;
    }
  }
  .title-h1 {
    font-size: 18px;
    line-height: 3.8vh;
    font-weight: bold;
    color: #555;
  }
  .title-h4 {
    font-size: 17px;
    font-weight: 575;
    color: #555;
  }
  .icon-fluid {
    width: 10px;
  }
}

@include media-breakpoint-between(md, lg) {
  .title-h1 {
    font-size: 24px;
    line-height: 4.8vh;
    font-weight: bold;
    color: #555;
  }
  .title-h4 {
    font-size: 18px;
    font-weight: 575;
    color: #555;
  }
  #accordionQuestions {
    .card-text {
      font-size: 18px;
    }
  }
  #collapseOne {
    ul {
      list-style-type: none;
      color: #777;
    }
    li {
      font-size: 18px;
      padding: 10px;
    }
  }
  #collapseThree {
    li {
      font-size: 18px;
      padding: 10px;
    }
  }
  .icon-fluid {
    width: 12px;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .title-h1 {
    font-size: 1.5rem;
    line-height: 4.8vh;
    font-weight: bold;
    color: #555;
  }
  .title-h4 {
    font-size: 1.15rem;
    font-weight: 575;
    color: #555;
  }
  #collapseOne {
    ul {
      list-style-type: none;
      color: #777;
    }
    li {
      font-size: 18px;
      padding: 10px;
    }
  }
  #collapseThree {
    li {
      font-size: 18px;
      padding: 10px;
    }
  }
  .icon-fluid {
    width: 14px;
  }
}
</style>